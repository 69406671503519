import 'font-awesome/css/font-awesome.min.css'

//forwarding UTMS
document.addEventListener('DOMContentLoaded', () => {
	const utms = window.location.search

	if (!utms) return

	const links = document.querySelectorAll('a.track').forEach(link => {
		const current = link.getAttribute('href')
		link.setAttribute('href', current + utms)
	})

	console.log(links)
})

